import { ClipLoader } from "react-spinners";

const ComponentLoader = () => {
  return (
    <div className="flex items-center min-h-[30vh] justify-center">
      <ClipLoader size={70} color={`#4821d1`} />
    </div>
  );
};

export default ComponentLoader;
