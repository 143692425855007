import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/light.css";
import moment from "moment";
import { useState } from "react";
import Flatpickr from "react-flatpickr";

import BrandsChart from "./BrandChart";
import BrandsVesselsChart from "./BrandVesselsChart";
import { currentDate, currentMonth, currentYear } from "utils/functions";

const BrandsAnalytics = () => {
  const [brandsId, setBrandsId] = useState(null);

  const [filter, setFilter] = useState({
    start_date: `${currentMonth()}/01/${currentYear()}`,
    end_date: `${currentMonth()}/${currentDate()}/${currentYear()}`,
  });
  const setDate = (newValue) => {
    setFilter(() => {
      return {
        ...filter,
        // daterange: `${startDate} - ${endDate}`,
        start_date: moment(newValue[0]).format("MM-DD-YYYY"),
        end_date: moment(newValue[1]).format("MM-DD-YYYY"),
      };
    });
  };
  return (
    <div className="flex flex-wrap my-6 -mx-3 ">
      <div className="w-full max-w-full px-3 flex-0 ">
        <div className="relative flex flex-col min-w-0 p-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div>
                <h5 className="mb-5 dark:text-white font-bold text-3xl text-blue-800">
                  Brands Analytics
                </h5>
              </div>
              <p className="mb-0 leading-normal text-sm"></p>
            </div>
            <div className="my-auto flex ml-auto lg:mt-0 ">
              {brandsId ? (
                <div>
                  <span
                    className="text-blue-800 hover:underline cursor-pointer"
                    onClick={() => setBrandsId(null)}
                  >
                    Brands
                  </span>{" "}
                  / <span dangerouslySetInnerHTML={{ __html: brandsId }}></span>
                </div>
              ) : (
                <></>
              )}
              <div className="my-auto ml-auto">
                <div className="flex">
                  <Flatpickr
                    options={{
                      mode: "range",
                      dateFormat: "m-d-Y",
                      defaultDate: [`${currentMonth()}-01-${currentYear()}`, `${currentMonth()}-${currentDate()}-${currentYear()}`]
                    }}
                    fromdateid="DashboardEndDatePicker"
                    selectvalue={[]}
                    className="form-control clickable  focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-fit appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 mx-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                    onChange={setDate}
                    placeholder="Select Date Range 🗓️"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-y-auto">
            {brandsId ? (
              <BrandsVesselsChart brandsId={brandsId} filter={filter} />
            ) : (
              <BrandsChart setBrandsId={setBrandsId} filter={filter} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandsAnalytics;
