
import VesselsAnalytics from "pages/Reports/VesselsAnalytics";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAdminVisitorQuery } from "store/Actions/adminAnalytics";
import { currentDate, currentMonth, currentYear } from "utils/functions";

const VisitorsAnalytics = () => {
  const [filter, setFilter] = useState({
    sort: "visitor_counts",
    order: "desc",
    perPage: 50,
    page: 1,
    start_date: `${currentMonth()}/01/${currentYear()}`,
    end_date: `${currentMonth()}/${currentDate()}/${currentYear()}`,
  });
  const { data, isFetching } = useAdminVisitorQuery(filter);

  const columns = [
    {
      header: "ip",
      accessor: "ip",
      Cell: ({ cell: { row } }) => {
        return (
          <Link
            to={`/account-details/view/${row.original.device_id}?to=${filter.end_date}&from=${filter.start_date}`}
            className=" text-blue-700 hover:text-blue-800"
          >
            {row.original.ip}
          </Link>
        );
      },
    },
    {
      header: "Page View",
      accessor: "visitor_counts",
      Cell: ({ cell: { value } }) => {
        return <span className={``}>{value.toLocaleString()}</span>;
      },
    }
  ];

  return (
    <div className="flex flex-wrap my-6 -mx-3">
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 p-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div>
                <h5 className="mb-5 dark:text-white font-bold text-3xl text-blue-800">
                  Visitors Analytics
                </h5>
                <p className="mb-0 leading-normal text-sm"></p>
              </div>
            </div>
          </div>

          <VesselsAnalytics
            ListQuery={{ data: data?.data, isLoading: isFetching }}
            filterProps={{ filter, setFilter }}
            columns={columns}
            sortingField={["ip", "visitor_counts"]}
            SearchId={false}
            searchByBrokerage={false}
          />
        </div>
      </div>
    </div>
  );
};

export default VisitorsAnalytics;
