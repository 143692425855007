import PrimaryButton from "Components/Butttons/PrimaryButton";
import DataTable from "Components/Table/Index";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  useBrokerDeleteMutation,
  useBrokerListQuery,
} from "store/Actions/broker";
import Swal from "sweetalert2";

const BrokerList = () => {
  // const [currentPage, setCurrentPage] = useState(1);

  const [filter, setFilter] = useState({
    sort: "first_name",
    order: "asc",
    perPage: 50,
    page: 1,
    searchField: " ",
    searchValue: " ",
  });
  const { data, isLoading, refetch } = useBrokerListQuery(filter);

  const nav = useNavigate();

  const [brokerDelete] = useBrokerDeleteMutation();
  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        brokerDelete(id).then((res) => {
          if (res.error) {
            toast.error(res.error.data.message);
          } else {
            toast.success(res.data?.message);
            if (data?.data?.data.length === 1) {
              setFilter((filter) => ({
                ...filter,
                page: data.data.last_page - 1,
              }));
            } else {
              refetch();
            }
          }
        });
      }
    });
  };
  const columns = useMemo(
    () => [
      {
        header: "Full Name",
        accessor: "fullname",
        Cell: ({ row }) =>
          `${row.original.first_name} ${row.original.last_name}`,
      },
      {
        header: "email",
        accessor: "email",
      },

      {
        header: "broker id",
        accessor: "related_entity_id",
      },
      {
        header: "status",
        accessor: "status",
        Cell: ({ cell: { value } }) => {
          return (
            <span
              className={`py-2.2 px-3.6 text-xs rounded-1.8 inline-block whitespace-nowrap text-center ${
                value === "active"
                  ? "bg-lime-200 text-lime-600 "
                  : "bg-red-200 text-red-600 "
              } align-baseline font-bold capitalize leading-none`}
            >
              {value}
            </span>
          );
        },
      },

      {
        header: "Action",
        accessor: "id",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="flex gap-2  items-center  font-semibold  ">
              <span
                className="cursor-pointer hover:text-orange-600 hover:underline"
                onClick={() => nav(`/broker/view/${value}`)}
              >
                View
              </span>
              {" | "}
              <span
                className="cursor-pointer hover:text-blue-600  hover:underline"
                onClick={() => nav(`/broker/edit/${value}`)}
              >
                Edit
              </span>
              {" | "}
              <span
                className="cursor-pointer hover:text-red-600  hover:underline"
                onClick={() => onDelete(value)}
              >
                Delete
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <div className="flex flex-wrap my-6 -mx-3">
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 break-words p-6 bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div>
                <h5 className="mb-5 dark:text-white font-bold text-3xl text-blue-800">
                  All Brokers
                </h5>
                <p className="mb-0 leading-normal text-sm"></p>
              </div>
              <div className="my-auto flex ml-auto lg:mt-0 ">
                <div className="my-auto ml-auto">
                  <PrimaryButton
                    event={() => nav("/broker/create")}
                    text=" Add broker"
                  />
                </div>
              </div>
            </div>
          </div>

          <DataTable
            ListQuery={{ data: data?.data, isLoading, refetch }}
            filterProps={{ filter, setFilter }}
            columns={columns}
            sortingField={["fullname", "related_entity_id", "status"]}
            searchFields={["fullname", "email", "related_entity_id"]}
          />
        </div>
      </div>
    </div>
  );
};

export default BrokerList;
