import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { DOTS, usePagination } from "utils/usePagination";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || !paginationRange?.length) {
    return null;
  }

  const onNext = () => {
    onPageChange(parseInt(currentPage) + 1);
  };

  const onPrevious = () => {
    onPageChange(parseInt(currentPage) - 1);
  };
  let lastPage =
    paginationRange && paginationRange[paginationRange?.length - 1];
  return (
    <nav className="dataTable-pagination select-none">
      <ul className="dataTable-pagination-list">
        <li
          onClick={() => currentPage != 1 && onPrevious()}
          className={`${
            currentPage == 1 ? "!cursor-not-allowed bg-slate-150  " : ""
          }`}
        >
          <ChevronLeftIcon className="h-5 " />
          <div className="arrow left" />
        </li>
        {paginationRange?.map((pageNumber, key) => {
          if (pageNumber === DOTS) {
            return (
              <li className="ellipsis" key={key}>
                {"..."}
              </li>
            );
          }

          return (
            <li
              key={key}
              className={`${pageNumber == currentPage ? "active" : ""}`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          // disabled={currentPage===lastPage?"disabled":""}
          className={`${
            currentPage == lastPage ? "!cursor-not-allowed bg-slate-150 " : ""
          }`}
          onClick={() => currentPage != lastPage && onNext()}
        >
          <ChevronRightIcon className="h-5 " />
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
