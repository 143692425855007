import { useState } from "react";
import { Link } from "react-router-dom";
import { useAdminVesselsQuery } from "store/Actions/adminAnalytics";
import { currentDate, currentMonth, currentYear } from "utils/functions";
import VesselsAnalytics from "./VesselsAnalytics";
const BrokerReport = () => {
  const [filter, setFilter] = useState({
    sort: "visitor_counts",
    order: "desc",
    perPage: 50,
    page: 1,
    searchField: "",
    searchValue: "",
    start_date: `${currentMonth()}-01-${currentYear()}`,
    end_date: `${currentMonth()}-${currentDate()}-${currentYear()}`,

  });
  const { data, isFetching, refetch } = useAdminVesselsQuery(filter);

  const columns = [
    {
      header: "vessel id",
      accessor: "vessel_id",
      Cell: ({ cell: { row } }) => {
        const name = `${row.original.vessel_id} - ${row.original.display_length_feet}' ${row.original.manufacturer}  ${row.original.year} `;
        return (
          <Link
            to={`/vessel-details/view/${row.original.vessel_id}`}
            className="flex items-center gap-3 flex-wrap cursor-pointer text-blue-700 hover:text-blue-800 w-fit"
          >
          <img
              src={row?.original?.vessel_image_url}
              className=" w-20 h-full shadow-soft-sm rounded-sm object-cover preview-img "
            />
            <div
              dangerouslySetInnerHTML={{ __html: name }}
              className="text-lg "
            ></div>
          </Link>
        );
      },
    },
    {
      header: "Visitors",
      accessor: "visitor_counts",
    },
    {
      header: "Guests",
      accessor: "guest_counts",
    }
  ];

  return (
    <div className="flex flex-wrap my-6 -mx-3">
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 p-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div>
                <h5 className="mb-0 dark:text-white font-bold text-3xl text-blue-800">
                  Vessels Analytics
                </h5>
                <p className="mb-0 leading-normal text-sm"></p>
              </div>
              <div className="my-auto flex ml-auto lg:mt-0 ">
                <div className="my-auto ml-auto"></div>
              </div>
            </div>
          </div>

          <VesselsAnalytics
            ListQuery={{ data: data?.data, isLoading:isFetching, refetch }}
            filterProps={{ filter, setFilter }}
            columns={columns}
            sortingField={[
              "visitor_counts",
              "guest_counts",
              "vessel_id",
            ]}
            searchFields={[]}
          />
        </div>
      </div>
    </div>
  );
};

export default BrokerReport;
