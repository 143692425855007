import Flatpickr from "react-flatpickr";
import DataTable from "Components/Table/Index";
import moment from "moment";
import { useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useAdminMyInquiresQuery } from "store/Actions/adminAnalytics";
import { currentDate, currentMonth, currentYear } from "utils/functions";

const AccountInquries = () => {
  const { deviceId } = useParams();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState({
    perPage: 50,
    page: 1,
    start_date: searchParams.get("from") ? searchParams.get("from") : `${currentMonth()}/01/${currentYear()}`,
    end_date: searchParams.get("to") ? searchParams.get("to") : `${currentMonth()}/${currentDate()}/${currentYear()}`,
  });

  const { data: list, isFetching } = useAdminMyInquiresQuery({ deviceId, filter });
  const setDate = (newValue) => {
    const startDate = moment(newValue[0]).format("MM-DD-YYYY");
    const endDate = moment(newValue[1]).format("MM-DD-YYYY");

    setFilter((prev) => {
      return {
        ...prev,
        start_date: startDate,
        end_date: endDate,
      };
    });
  };
  const columns = [
    {
      header: "Vessel ID",
      accessor: "data.vesselid",
      Cell: ({ cell: { row } }) => {
        return (

          <Link to={`/vessel-details/view/${row.original.vessel_id}`} className="flex items-center gap-3 flex-wrap cursor-pointer text-blue-700 hover:text-blue-800 w-fit">
            {row.original.vessel_id}
          </Link>
        );
      },
    },
    {
      header: "Contacted At",
      accessor: "updated",
      Cell: ({ cell: { value } }) => {
        return value;
      },
    },
    {
      header: "Message",
      accessor: "message",
      Cell: ({ cell: { value } }) => {
        return (
          <div className="whitespace-pre-wrap">
            {value}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="text-lg text-blue-800 flex justify-between px-3">
        <span>Inquiries</span>
      </div>
      <div className="flex w-full gap-4  p-6 pb-0 max-w-6/12 ">
        <div className="my-auto ml-auto">
          <div className=" flex">
          <Flatpickr
                  options={{
                      mode: "range",
                      dateFormat: "m-d-Y",
                      defaultDate: [
                          filter.start_date,
                          filter.end_date
                      ],
                  }}
                  fromdateid="DashboardEndDatePicker"
                  selectvalue={[]}
                  className="form-control clickable  focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-fit appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 mx-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                  onClose={setDate}
                  placeholder="Select Date Range 🗓️"
              />
          </div>
        </div>
      </div>
      <DataTable
        ListQuery={{ data: list?.data, isLoading: isFetching }}
        filterProps={{ filter, setFilter }}
        columns={columns}
        sortingField={["created_at",]}
        SearchId={true}
        searchPlaceHolder="Vessel Id"
        hideFilter={true}
      />
    </>
  )
}

export default AccountInquries