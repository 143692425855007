import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/light.css";
import moment from "moment";
import { useState } from "react";
import Flatpickr from "react-flatpickr";
import AnalyticsVesselGraph from "./AnalyticsVesselGraph";
import BrokerAnalyticsGraph from "./BrokerAnalyticsGraph";
import BrokerageAnalyticsGraph from "./BrokerageAnalyticsGraph";
import { currentDate, currentMonth, currentYear } from "utils/functions";

const VesselAnalyticsGraph = () => {
  // const [brandsId, setBrandsId] = useState(null);
  const [graphInfo, setGraphInfo] = useState({
    currentGraph: "brokerage",
    broker: null,
    brokerage: null,

  });

  const [filter, setFilter] = useState({
    ...graphInfo,
      start_date: `${currentMonth()}-01-${currentYear()}`,
      end_date: `${currentMonth()}-${currentDate()}-${currentYear()}`,
  });
  const setDate = (newValue) => {
    const startDate = moment(newValue[0]).format("MM-DD-Y");
    const endDate = moment(newValue[1]).format("MM-DD-Y");
    setFilter(() => {
      return {
        ...filter,
        start_date: startDate,
        end_date: endDate,
        // daterange: `${startDate} - ${endDate}`,
      };
    });
  };
  return (
    <div className="flex flex-wrap my-6 -mx-3 ">
      <div className="w-full max-w-full px-3 flex-0 ">
        <div className="relative flex flex-col min-w-0 p-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div>
                <h5 className="mb-5 dark:text-white font-bold text-3xl text-blue-800">
                  Brokerage Analytics
                </h5>
              </div>
              <p className="mb-0 leading-normal text-sm"></p>
            </div>
            <div className="my-auto flex ml-auto lg:mt-0 ">
              {graphInfo.currentGraph !== "brokerage" && (
                <div>
                  {" "}
                  <span
                    className="text-blue-800 hover:underline cursor-pointer"
                    onClick={() =>
                      setGraphInfo((graphInfo) => ({
                        ...graphInfo,
                        currentGraph: "brokerage",
                        broker: null,
                        brokerage: null,
                      }))
                    }
                  >
                    Brokerage
                  </span>{" "}
                  {graphInfo?.brokerage && (
                    <span
                      className="text-blue-800 hover:underline cursor-pointer"
                      onClick={() =>
                        setGraphInfo((graphInfo) => ({
                          ...graphInfo,
                          currentGraph: "broker",
                          broker: null,
                        }))
                      }
                      dangerouslySetInnerHTML={{
                        __html: `/ ${graphInfo?.brokerage}`,
                      }}
                    ></span>
                  )}
                  {graphInfo?.broker && (
                    <span
                      className="text-blue-800 hover:underline cursor-pointer hover:text-blue-900"
                      dangerouslySetInnerHTML={{
                        __html: `/ ${graphInfo?.broker}`,
                      }}
                    ></span>
                  )}
                </div>
              )}
              <div className="my-auto ml-auto">
                <div className="flex">
                  <Flatpickr
                    options={{
                      mode: "range",
                      dateFormat: "m-d-Y",
                      defaultDate: [`${currentMonth()}-01-${currentYear()}`, `${currentMonth()}-${currentDate()}-${currentYear()}`]
                    }}
                    fromdateid="DashboardEndDatePicker"
                    selectvalue={[]}
                    className="form-control clickable  focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-fit appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 mx-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                    onChange={setDate}
                    placeholder="Select Date Range 🗓️"

                  />
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-y-auto">
            {graphInfo.currentGraph === "brokerage" ? (
              <BrokerageAnalyticsGraph
                graphState={{ graphInfo, setGraphInfo }}
                filter={filter}
              />
            ) : graphInfo.currentGraph === "broker" ? (
              <BrokerAnalyticsGraph
                graphState={{ graphInfo, setGraphInfo }}
                filter={filter}
              />
            ) : (
              <AnalyticsVesselGraph
                graphState={{ graphInfo, setGraphInfo }}
                filter={filter}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VesselAnalyticsGraph;
