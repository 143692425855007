import HighChart from "Components/Chart/HighChart";
import Highcharts from "highcharts/highstock";
import ComponentLoader from "Components/Loader/ComponentLoader";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/light.css";
import { useEffect, useState } from "react";
import CustomEvents from "highcharts-custom-events";
import { useBrandsVesselGraphQuery } from "store/Actions/BrandAnalytics";
import { useNavigate } from "react-router-dom";
CustomEvents(Highcharts);
const BrandsVesselsChart = ({ brandsId, filter }) => {
  const [chartOptions, setChartOptions] = useState(null);
  const { data, isLoading } = useBrandsVesselGraphQuery({
    filter,
    brandsId,
  });
  const nav =useNavigate()
  useEffect(() => {
    if (data && data.data && data.data.data) {
      const categories = data.data.data.map(
        (val) =>
          `${val.vessel_id}-${val.display_length_feet}'${val.year}`,
      );
      const sData = data.data.data.map((val) => ({
        name: val.vessel_id,
        y: Number(val.vessel_count),
      }));

      const options = {
        rangeSelector: {
          selected: 4,
        },
        chart: {
          renderTo: "chartContainer",
          type: "column",
          showAxes: true,
          marginRight: 0,
          height: 480,
          spacingRight: 0,
          zoomType: "x",
        },
        title: {
          text: "Brands Analytics",
        },
        xAxis: {
          min: 0,
          max: sData.length > 100 ? 100 : null,
          categories: [...categories],
          tickLength: 0,
          lineColor: "#ededed",
          labels: {
            rotation: -90,
            align: "right",
            style: {
              fontSize: "13px",
              fontFamily: "Verdana, sans-serif",
            },
            events: {
              click: function () {
                const Vessel = data.data.data.filter(
                  (val) =>
                    `${val.vessel_id}-${val.display_length_feet}'${val.year}` ==
                    this.value,
                )[0];

                nav(`/vessel-details/view/${Vessel.vessel_id}?from=${filter.start_date}&to=${filter.end_date}`)
              },
            },
          },
          
        },
        scrollbar: { enabled: true },
        yAxis: {
          allowDecimals: false,
          showFull: false,
          title: {
            text: "",
          },
          stackLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat(this.total, 0, "", "");
            },
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            cursor: "pointer",
            turboThreshold: 5000, //set it to a larger threshold, it is by default to 1000
          },
          column: {
            stacking: "normal",
            point: {
              events: {
                click: function () {
                  const Vessel = data.data.data.filter(
                    (val) =>
                      `${val.vessel_id}-${val.display_length_feet}'${val.year}` ==
                      this.category,
                  )[0];
  
                  nav(`/vessel-details/view/${Vessel.vessel_id}?from=${filter.start_date}&to=${filter.end_date}`)
                },
              },
            },
          },
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            const Vessel = data.data.data.filter(
              (val) =>
                `${val.vessel_id}-${val.display_length_feet}'${val.year}` ==
                this.x,
            )[0];
            let tooltipContent = `<div style="height:130px"> <b>${Vessel?.vessel_id}</b><br/>`;
            if (Vessel?.image_display == "block") {
              tooltipContent += `<img src="${
                Vessel?.vessel_image ||
                "https://www.yachtbroker.org/img/vessel_medium.jpg"
              }" style="max-width: 100px; max-height: 100px;" />`;
            }
            tooltipContent += `Visitors: ${Vessel?.vessel_count}<br/>`;
            tooltipContent += `Display Length (Feet): ${Vessel?.display_length_feet}<br/>`;
            tooltipContent += `Year: ${Vessel?.year}<br/></div> `;
            // tooltipContent += `<img src="${Vessel?.vessel_image}" style="max-width: 100px; max-height: 100px;"/>`;

            return tooltipContent;
          },
        },
        series: [
          {
            name: "Vessels",
            colorByPoint: true,
            data: [...sData],
          },
        ],
      };

      setChartOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="overflow-y-auto">
      {" "}
      {isLoading ? (
        <ComponentLoader />
      ) : (
        <HighChart chartOptions={chartOptions} />
      )}
    </div>
  );
};

export default BrandsVesselsChart;
