// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating brokerManagement by enhancing and injecting endpoints using serverApi
const adminData = serverApi
  .enhanceEndpoints({ tagTypes: [""] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Query endpoint for retrieving a list of brokers
      activityLog: builder.query({
        query(filter) {
          // Encode the object's data into URL parameters
          const queryString = new URLSearchParams(filter?.searchObj).toString();
          return {
            url: `activity-log?page=${filter.page || 1}&per_page=${filter?.perPage || 50
              }&sort_field=${filter?.sort || "created_at"}&sort_order=${filter?.order || "desc"
              }&start_date=${filter?.start_date || ""}&end_date=${filter?.end_date || ""
              }&${queryString && queryString}`,
            method: "GET",
          };
        },
        // Provides broker tags for caching broker list data
        keepUnusedDataFor: 60,
      }),
      botAnalytics: builder.query({
        query() {
          return {
            url: `bot-analytics`,
            method: "GET",
          };
        },
        // Provides user tags for caching user profile data
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const { useActivityLogQuery, useBotAnalyticsQuery } = adminData;
