import VesselsAnalytics from "pages/Reports/VesselsAnalytics";
import {  useState } from "react";
import { Link } from "react-router-dom";
import { useAdminGuestQuery } from "store/Actions/adminAnalytics";
import { currentDate, currentMonth, currentYear } from "utils/functions";

const GuestAnalytics = () => {
  const [filter, setFilter] = useState({
    sort: "guest_counts",
    order: "desc",
    perPage: 50,
    page: 1,
    searchField: " ",
    searchValue: " ",
    start_date: `${currentMonth()}/01/${currentYear()}`,
    end_date: `${currentMonth()}/${currentDate()}/${currentYear()}`,
  });
  const { data, isFetching } = useAdminGuestQuery(filter);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = [
    {
      header: "Full Name ",
      accessor: "fullname",
      Cell: ({ cell: { row } }) => {
        const name = ` ${row?.original?.fullname}`;
        return (
          <Link
            to={`/account-details/view/${row.original.device_id}?to=${filter.end_date}&from=${filter.start_date}`}
            className="flex items-center gap-3 flex-wrap cursor-pointer text-blue-700 hover:text-blue-800 w-fit"
          >
            {" "}
            {name}
          </Link>
        );
      },
    },
    {
      header: "Email ",
      accessor: "email",
    },
    {
      header: "IP ",
      accessor: "ip",
    },
    {
      header: "Page View",
      accessor: "guest_counts",
      Cell: ({ cell: { value } }) => {
        return <span className={`text-lg`}>{value.toLocaleString()}</span>;
      },
    }
  ];

  return (
    <div className="flex flex-wrap my-6 -mx-3">
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 p-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div>
                <h5 className="mb-5 dark:text-white font-bold text-3xl text-blue-800">
                  Guest Analytics
                </h5>
                <p className="mb-0 leading-normal text-sm"></p>
              </div>
            </div>
          </div>

          <VesselsAnalytics
            ListQuery={{ data: data?.data, isLoading: isFetching }}
            filterProps={{ filter, setFilter }}
            columns={columns}
            sortingField={["fullname","email", "guest_counts"]}
            searchByGuest={true}
          />
        </div>
      </div>
    </div>
  );
};

export default GuestAnalytics;
