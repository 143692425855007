// Importing serverApi from the "store/serverApi" module
import { serverApi } from "store/serverApi";

// Creating brokerManagement by enhancing and injecting endpoints using serverApi
const categoriesAnalytics = serverApi.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    // Query endpoint for retrieving a list of brokers
    categoryGraph: builder.query({
      query(filter) {
        const { ...value } = filter
        const queryString = new URLSearchParams(value).toString();
        return {
          url: `category-graphs?${queryString && queryString}`,
          method: "GET",
        };
      },
      // Provides broker tags for caching broker list data
      keepUnusedDataFor: 1,
    }),
    categoryVesselGraph: builder.query({
      query({ categoryId, filter }) {
        const { ...value } = filter
        const queryString = new URLSearchParams(value).toString();
        return {
          url: `category-vessel-graphs/${categoryId}?${queryString && queryString}`,
          method: "GET",
        };
      },
      // Provides broker tags for caching broker list data
      keepUnusedDataFor: 1,
    }),
  }),
});

export const { useCategoryGraphQuery, useCategoryVesselGraphQuery } =
  categoriesAnalytics;
