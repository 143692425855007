import ErrorPage from "pages/ErrorPage/Index";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { loggedUserRole } from "utils/functions";

const ProtectedLogin = ({ accessTo = [], children, name = "" }) => {
  useEffect(() => {
    document.title = name ? `Yachtr | ${name}` : "Yachtr";
  }, [name]);

  const token = localStorage.getItem("token");
  const userRole = loggedUserRole();

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (!accessTo.includes(userRole)) {
    return <ErrorPage />;
  }

  return children;
};

export default ProtectedLogin;
