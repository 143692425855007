import Flatpickr from "react-flatpickr";
import DataTable from "Components/Table/Index"
import moment from "moment";
import { useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useAdminMyVisitsQuery } from "store/Actions/adminAnalytics";
import { currentDate, currentMonth, currentYear } from "utils/functions";

const AccountVisits = () => {
    const { deviceId } = useParams();
    const [searchParams] = useSearchParams();
    const [filter, setFilter] = useState({
        sort: "visitor_counts",
        order: "desc",
        perPage: 50,
        page: 1,
        start_date: searchParams.get("from") ? searchParams.get("from") : `${currentMonth()}/01/${currentYear()}`,
        end_date: searchParams.get("to") ? searchParams.get("to") : `${currentMonth()}/${currentDate()}/${currentYear()}`,
    });
    //    // eslint-disable-next-line no-console
    //    console.log(searchParams.get("from"));
    const { data: list, isFetching } = useAdminMyVisitsQuery({ deviceId, filter });

    const setDate = (newValue) => {
        const startDate = moment(newValue[0]).format("MM-DD-YYYY");
        const endDate = moment(newValue[1]).format("MM-DD-YYYY");

        setFilter((prev) => {
            return {
                ...prev,
                start_date: startDate,
                end_date: endDate,
            };
        });
    };

    const columns = [
        {
            header: "vessel id",
            accessor: "vessel_id",
            Cell: ({ cell: { row } }) => {
                const name = `${row.original.vessel_id}`;
                return (

                    <Link to={`/vessel-details/view/${row.original.vessel_id}?to=${filter.end_date}&from=${filter.start_date}`} className="flex items-center gap-3 flex-wrap cursor-pointer text-blue-700 hover:text-blue-800 w-fit">
                        <img
                            src={row?.original?.vessel_image_url}
                            className=" w-20 h-full shadow-soft-sm rounded-sm object-cover preview-img "
                        />
                        <div
                            dangerouslySetInnerHTML={{ __html: name }}
                            className="text-lg "
                        ></div>
                    </Link>
                );
            },
        },
        {
            header: "Page Views",
            accessor: "visitor_counts",
            Cell: ({ cell: { value } }) => {
                return value;
            },
        }
    ];

    return (
        <>
            <div className="text-lg text-blue-800 flex justify-between px-3">
                <span> Visited Vessels</span>
            </div>
            <div className="flex w-full gap-4  p-6 pb-0 max-w-6/12 ">
                <div className="my-auto ml-auto">
                    <div className=" flex">
                        <Flatpickr
                            options={{
                                mode: "range",
                                dateFormat: "m-d-Y",
                                defaultDate: [
                                    filter.start_date,
                                    filter.end_date
                                ],
                            }}
                            fromdateid="DashboardEndDatePicker"
                            selectvalue={[]}
                            className="form-control clickable  focus:shadow-soft-primary-outline dark:bg-gray-950 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease-soft block w-fit appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 mx-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-yachtrBlue focus:outline-none "
                            onClose={setDate}
                            placeholder="Select Date Range 🗓️"
                        />
                    </div>
                </div>
            </div>
            <DataTable
                ListQuery={{ data: list?.data, isLoading: isFetching }}
                filterProps={{ filter, setFilter }}
                columns={columns}
                sortingField={["vessel_id",'visitor_counts']}
                SearchId={true}
                searchPlaceHolder="Vessel Id"
                hideFilter={true}
            />
        </>
    )
}

export default AccountVisits