import DataTable from "Components/Table/Index";
import moment from "moment";
import { useMemo, useState } from "react";
import { loggedUserRole } from "utils/functions";
import MoreInfoModal from "./MoreInfoModal";
import { Link } from "react-router-dom";

const BoatInquiryTable = ({ ListQuery, filterProps }) => {
  const columns = useMemo(
    () => [
      {
        header: "Vessel ID",
        accessor: "data.vesselid",
        Cell: ({ cell: { row } }) => {
          return (
            <Link
              to={`/vessel-details/view/${row.original.data.vesselid}?from=${moment(row.original.data.created_at).format("MM-DD-YYYY")}&to=${moment(row.original.data.created_at).format("MM-DD-YYYY")}`}
              className="cursor-pointer py-2.2 px-3.6 text-xs rounded-1.8 whitespace-nowrap text-center align-baseline font-semibold capitalize leading-none bg-gray-50 text-gray-600 flex  w-fit"
              rel="noreferrer"
            >
              {row.original.data.vesselid}
            </Link>
          );
        },
      },
      {
        header: "Contact Details",
        accessor: "data.email",

        Cell: ({ cell: { row } }) => {
          return (
            <div className=" flex flex-col">
              <Link
                to={`/account-details/view/${row.original.user_id}?from=${moment(row.original.created_at).format("MM-DD-YYYY")}&to=${moment(row.original.created_at).format("MM-DD-YYYY")}`}
                className="cursor-pointer py-2.2 px-3.6 text-xs rounded-1.8 whitespace-nowrap text-center align-baseline font-semibold capitalize leading-none bg-gray-50 text-gray-600 flex  w-fit"
                rel="noreferrer"
              >
                {row.original.data.fullname && row.original.data.fullname}
              </Link>
              {row.original.data.email && <a href={`mailto: ${row.original.data.email}`} className="normal-case py-1.2 px-2.6 text-xs rounded-1.8  whitespace-nowrap text-center  align-baseline font-semibold bg-slate-200 text-slate-700  leading-none m-1 flex flex-wrap w-fit ">{row.original.data.email}</a>}
              {row.original.data.phone && <a href={`tel: ${row.original.data.phone}`} className={` py-1.2 px-2.6 text-xs rounded-1.8  whitespace-nowrap text-center  align-baseline font-semibold bg-slate-200 text-slate-700  leading-none m-1 flex flex-wrap w-fit`} >{row.original.data.phone}</a>}
            </div>
          );
        },
      },
      {
        header: "Contacted At",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => {
          return (
            <span className={``}>
              {moment(value).format("MM-DD-YYYY HH:mm:ss")}
            </span>
          );
        },
      },
      {
        header: "Message",
        accessor: "data.message",
        Cell: ({ cell: { value } }) => {
          return (
            <div className=" w-120 whitespace-pre-wrap">
              {value}
            </div>
          );
        },
      },
      {
        header: " Brokerage Name",
        accessor: "data.brokeragename",
      },
      {
        header: " Broker Name",
        accessor: "data.brokername",
      },
      {
        header: "more info",
        accessor: "count",
        Cell: ({ cell: { row } }) => {
          return (
            <span
              className="cursor-pointer py-2.2 px-3.6 text-xs rounded-1.8 inline-block whitespace-nowrap text-center align-baseline font-bold capitalize leading-none bg-gray-200 text-gray-600 "
              onClick={() => {
                openModal(row?.original);
              }}
            >
              more info ..
            </span>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  let [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal(data) {
    setModalData(data);
    setIsOpen(true);
  }

  return (
    <div className="flex flex-wrap my-6 -mx-3">
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 break-words bg-white border-0 p-6 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div>
                <h5 className="mb-0 dark:text-white font-bold text-3xl text-blue-800">
                  {loggedUserRole() === "Guest"
                    ? "My Inquiries"
                    : loggedUserRole() === "Admin"
                      ? "Vessel  Inquiries"
                      : "Boat Inquiries"}
                </h5>
                <p className="mb-0 leading-normal text-sm"></p>
              </div>
              <div className="my-auto flex ml-auto lg:mt-0 ">
                <div className="my-auto ml-auto"></div>
              </div>
            </div>
          </div>

          <DataTable
            ListQuery={ListQuery}
            filterProps={filterProps}
            columns={columns}
            sortingField={["data.vesselid", "data.fullname", "created_at"]}
          // searchFields={searchFields}
          />
        </div>
      </div>

      <MoreInfoModal
        closeModal={closeModal}
        isOpen={isOpen}
        modalData={modalData}
      />
    </div>
  );
};

export default BoatInquiryTable;
