import { useEffect, useState } from "react";
import { loggedUserRole } from "utils/functions";
import { Tab, TabGroup, TabList, TabPanel } from "@headlessui/react";
import { generateDateRanges } from "utils/functions";
import TabItem from "./TabItem";
import BotChart from "./BotChart";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const dateRanges = generateDateRanges();

const Dashboard = () => {
  const [role, setRole] = useState(null);
  useEffect(() => {
    setRole(loggedUserRole());
    //      // eslint-disable-next-line no-console
    //     console.log(loggedUserRole());
  }, []);

  return (
    <>
      <div className="w-full p-6 mx-auto">
        <TabGroup>
          <TabList className="flex flex-col sm:flex-row  space-x-1 mx-4 rounded-xl  p-1">
            {dateRanges.map((range,) => (
              <Tab
                key={range.text}
                className={({ selected }) =>
                  classNames(
                    "w-auto rounded-lg p-4 mx-2 text-sm  font-medium leading-5",
                    "inline-block px-8 py-2 m-0 text-sm font-bold text-center   align-middle transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft  bg-150 bg-x-25 hover:scale-102 active:opacity-85 ",
                    selected
                      ? " text-blue-50  bg-gradient-to-tl from-yachtrDarkBlue to-yachtrBlue shadow-soft-md"
                      : " hover:bg-white/[0.12] hover:text-gray-800"
                  )
                }
              >
                {range.text}
              </Tab>
            ))}
          </TabList>
          {dateRanges.map((range,) => (
            <TabPanel
              key={range.text}>
              <TabItem
                key={range.text} start_date={range.start_date} end_date={range.end_date} />
            </TabPanel>
          ))}
        </TabGroup>
      </div>
      <div className="w-full p-6 mx-auto">
          {(role === "Admin") && <BotChart />}
      </div>
    </>
  );
};

export default Dashboard;
