import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/light.css";
import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighChart from "Components/Chart/HighChart";
import SkeletonLoader from "Components/Loader/SkeletonLoader";
import CustomEvents from "highcharts-custom-events";
import { useBrokerageAnalyticsGraphQuery } from "store/Actions/vesselsAnalyticsGraph";

CustomEvents(Highcharts);
function BrokerageGraph({ graphState, filter }) {
  const { setGraphInfo } = graphState;
  const { data, isLoading } = useBrokerageAnalyticsGraphQuery(filter, {
    skip: !filter || (!filter?.start_date && !filter?.date_range),
  });

  const [chartOptions, setChartOptions] = useState(null);

  useEffect(() => {
    if (data) {
      var categories = [];
      if (data?.data && !isLoading ) {
        categories = data?.data?.slice(0, 10).map((val) => val.brokerage_name);
      }

      const sData = [
        {
          name: "visitors",
          data: data?.data
            ?.slice(0, 10)
            .map((val) => Number(val.visitor_count)),
        },
        {
          name: "guest",
          data: data?.data?.slice(0, 10).map((val) => Number(val.guest_count)),
        },
      ];

      const options = {
        chart: {
          renderTo: "chartContainer",
          type: "column",
          showAxes: true,
          marginRight: 0,
          spacingRight: 0,
          //   height: 480,
          zoomType: "x",
        },
        title: {
          text: `Brokerage Analytics`,
        },
        xAxis: {
          allowDecimals: false,
          min: 0,
          max: categories.length > 50 ? 50 : null,
          categories: [...categories],
          tickLength: 0,
          lineColor: "#ededed",
          labels: {
            rotation: -90,
            align: "right",
            style: {
              fontSize: "13px",
              fontFamily: "Verdana, sans-serif",
            },
            formatter: function () {
              return this.value.length > 8
                ? `${this.value.substring(6, 0)}...`
                : this.value;
            },
            events: {
              click: function () {
                setGraphInfo((graphInfo) => ({
                  ...graphInfo,
                  currentGraph: "broker",
                  brokerage: this.category,
                }));
              },
            },
          },
        },

        yAxis: {
          allowDecimals: false,
          showFull: false,
          title: {
            text: "",
          },
          stackLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat(this.total, 0, "", "");
            },
          },
        },
        tooltip: {
          enabled: true,
          formatter: function () {
            let tooltipContent = `<div style="height:130px ; text-transform: capitalize;"> <b>${this?.x}</b><br/>`;

            tooltipContent += `${this.series.name} Count: ${this?.y}<br/></div> `;

            return tooltipContent;
          },
        },
        legend: {
          enabled: true,
        },

        plotOptions: {
          series: {
            stacking: "normal",
            cursor: "pointer",
            turboThreshold: 5000, //set it to a larger threshold, it is by default to 1000
          },
          column: {
            stacking: "normal",
            point: {
              events: {
                click: function () {
                  setGraphInfo((graphInfo) => ({
                    ...graphInfo,
                    currentGraph: "broker",
                    brokerage: this.category,
                  }));
                },
              },
            },
          },
        },
        series: [...sData],
      };

      setChartOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {" "}
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <HighChart chartOptions={chartOptions} />
      )}
    </>
  );
}

export default BrokerageGraph;
