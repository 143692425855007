import VesselsAnalytics from "pages/Reports/VesselsAnalytics";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useAdminVesselsQuery } from "store/Actions/adminAnalytics";
import { currentDate, currentMonth, currentYear } from "utils/functions";

const AdminVesselsAnalytics = () => {
  const [filter, setFilter] = useState({
    sort: "visitor_counts",
    order: "desc",
    perPage: 50,
    page: 1,
    start_date: `${currentMonth()}/01/${currentYear()}`,
    end_date: `${currentMonth()}/${currentDate()}/${currentYear()}`,
  });
  const { data, isFetching } = useAdminVesselsQuery(filter);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(
    () => [
      {
        header: "vessel id",
        accessor: "vessel_id",
        Cell: ({ cell: { row } }) => {
          const name = `${row.original.vessel_id} ${row.original.brokerage_name} - ${row.original.display_length_feet}' ${row.original.manufacturer}  ${row.original.year} `;
          return (
            <Link
              to={`/vessel-details/view/${row.original.vessel_id}?from=${filter.start_date}&to=${filter.end_date}`}
              className="flex items-center gap-3 flex-wrap cursor-pointer text-blue-700 hover:text-blue-800 w-fit"
            >
              <img
                src={row?.original?.vessel_image_url}
                className=" w-20 h-full shadow-soft-sm rounded-sm object-cover preview-img "
              />
              <div
                dangerouslySetInnerHTML={{ __html: name }}
                className="text-lg "
              ></div>
            </Link>
          );
        },
      },
      {
        header: "Visitors",
        accessor: "visitor_counts",
        Cell: ({ cell: { value } }) => {
          return <span className={``}>{value.toLocaleString()}</span>;
        },
      },
      {
        header: "Guests",
        accessor: "guest_counts",
        Cell: ({ cell: { value } }) => {
          return <span className={``}>{value.toLocaleString()}</span>;
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  );

  return (
    <div className="flex flex-wrap my-6 -mx-3">
      <div className="w-full max-w-full px-3 flex-0">
        <div className="relative flex flex-col min-w-0 p-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
          <div className="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
            <div className="lg:flex">
              <div>
                <h5 className="mb-5 dark:text-white font-bold text-3xl text-blue-800">
                  Vessels Analytics
                </h5>
                <p className="mb-0 leading-normal text-sm"></p>
              </div>
            </div>
          </div>

          <VesselsAnalytics
            ListQuery={{ data: data?.data || false, isLoading: isFetching }}
            filterProps={{ filter, setFilter }}
            columns={columns}
            sortingField={[
              "vessel_id",
              "visitor_counts",
              "guest_counts",
            ]}
            SearchId={true}
            searchPlaceHolder="Search By Vessel ID"
            searchByBrokerage={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminVesselsAnalytics;
