import MiniTAble from "Components/Table/MiniTAble";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAdminVesselsGuestQuery } from "store/Actions/adminAnalytics";

const VesselsGuest = ({ filterProps, totalCount = 0 }) => {
  const [filter, setFilter] = useState({
    perPage: 50,
    page: 1,
    start_date: filterProps?.filter?.start_date,
    end_date: filterProps?.filter?.end_date,
  });
  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      end_date: filterProps?.filter?.end_date,
      start_date: filterProps?.filter?.start_date,
    }));
  }, [filterProps.filter.end_date, filterProps?.filter?.start_date]);
  const { vesselId } = useParams();
  const { data, isFetching } = useAdminVesselsGuestQuery({ vesselId, filter });
  const columns = [
    {
      header: " name",
      accessor: "id",
      Cell: ({ cell: { row } }) => {
        return (
          <Link
            to={`/account-details/view/${row.original.device_id}?to=${filter.end_date}&from=${filter.start_date}`}
            className=" text-blue-700 hover:text-blue-800"
          >
            {` ${row?.original?.fullname || ""} `}
          </Link>
        );
      },
    },
    {
      header: "Page Views",
      accessor: "guest_counts",
      Cell: ({ cell: { value } }) => {
        return <span className={``}>{value.toLocaleString()}</span>;
      },
    }
  ];

  return (
    <>
      <div className="text-lg text-blue-800 flex justify-between px-3">
        <span> Guest Count</span>
        <span>{totalCount}</span>
      </div>
      <MiniTAble
        ListQuery={{ data: data?.data, isLoading: isFetching }}
        filterProps={{ filter: filter, setFilter: setFilter }}
        columns={columns}
        sortingField={["created_at"]}
        SearchId={true}
        searchPlaceHolder="Vessel Id"
        // searchFields={["vessel_name", "ip_address"]}
      />
    </>
  );
};

export default VesselsGuest;
