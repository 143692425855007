// import ComponentLoader from "Components/Loader/ComponentLoader";
import Pagination from "Components/Pagination/Pagination";
import { perPageOptions } from "utils/data";
import ListTable from "./ListTable";

const DataTable = ({
  ListQuery,
  filterProps,
  columns,
  sortingField,
  searchFields,
}) => {
  const { data, isLoading, isFetching } = ListQuery;
  const { filter, setFilter } = filterProps;
  const handleSort = (name) => {
    if (filter.sort === name) {
      setFilter((prev) => ({
        ...prev,
        order: prev.order === "asc" ? "desc" : "asc",
      }));
    } else {
      setFilter((prev) => ({ ...prev, sort: name, order: "asc" }));
    }
  };

  return (
    <>
      <div className="dataTable-wrapper">
        {data?.data ? (
          <div className="dataTable-top">
            <div className="dataTable-dropdown">
              <label>
                <select
                  className="dataTable-selector"
                  value={filter.perPage}
                  onChange={(e) => {
                    setFilter((prev) => ({
                      ...prev,
                      ...{ perPage: e.target.value, page: 1 },
                    }));
                  }}
                >
                  {perPageOptions?.map((option) => (
                    <option key={option.name} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>{" "}
                entries per page
              </label>
            </div>
            <div>
              {/* {!hideFilter && (
                <button
                  type="button"
                  className="inline-block px-4 py-2 m-0 text-xs  text-center  transition-all border-0 rounded-lg cursor-pointer ease-soft-in leading-pro tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85   whitespace-nowrap bg-slate-200 text-slate-800 align-baseline font-bold uppercase    "
                  onClick={() => {
                    setFilter({
                      start_date: `${currentMonth()}-01-${currentYear()}`,
                      end_date: `${currentMonth()}-${currentDate()}-${currentYear()}`,
                    });
                  }}
                >
                  clear Filter
                </button>
              )} */}
            </div>
          </div>
        ) : (
          <div className="dataTable-top"></div>
        )}
        {/* {isLoading ? (
          <ComponentLoader />
        ) :  */}

        <div className=" overflow-auto min-w-full md:max-h-50-screen ">
          <ListTable
            isFetching={isFetching}
            isLoading={isLoading}
            columns={columns}
            data={data?.data}
            filter={filter}
            setFilter={setFilter}
            handleSort={(e) => handleSort(e)}
            sortingField={sortingField}
            searchFields={searchFields}
          />
        </div>
        {data?.data?.length ? (
          <div className="dataTable-bottom mt-5">
            <div className="dataTable-info">
              {isLoading || isFetching
                ? "Loading..."
                : `Showing ${data?.from ? data?.from : (data?.current_page - 1) * data?.per_page + 1} to ${data?.to ? data?.to : data?.total < data?.current_page * data?.per_page ? data?.total : data?.current_page * data?.per_page} of ${data?.total} entries`}
            </div>
            {isLoading || isFetching ? (
              <></>
            ) : (
              <Pagination
                currentPage={data?.current_page}
                totalCount={data?.total}
                pageSize={data?.per_page}
                onPageChange={(page) => {
                  setFilter((prev) => ({
                    ...prev,
                    ...{ page: page },
                  }));
                }}
              />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default DataTable;
