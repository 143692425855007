import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/outline";
import { routesData } from "Routes/RoutesData";

export function AddressBar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Compute path segments dynamically when the pathname changes
  const pathSegments = useMemo(() => {
    const segments = pathname.split("/").slice(1);
    const specialIndexes = ["edit", "view"].map((key) => segments.indexOf(key));
    const firstSpecialIndex = Math.max(...specialIndexes);
    return firstSpecialIndex !== -1
      ? segments.slice(0, firstSpecialIndex + 1)
      : segments;
  }, [pathname]);

  // Generate paths for breadcrumb links
  const generatedPaths = useMemo(() => {
    return pathSegments.map((_, index) =>
      `/${pathSegments.slice(0, index + 1).join("/")}`
    );
  }, [pathSegments]);

  // Filter routes that match the generated paths
  const filteredRoutes = useMemo(() => {
    return routesData.filter((route) =>
      generatedPaths.some((path) =>
        path.includes("view") || path.includes("edit")
          ? route?.path.includes(path)
          : route.path === path
      )
    );
  }, [generatedPaths]);

  return filteredRoutes.length > 0 ? (
    <div className="flex items-center w-fit gap-x-2 p-3.5 lg:px-5 lg:py-3 rounded-3xl text-slate-600/70">
      <div className="flex gap-x-2 text-sm font-medium items-center">
        {/* Home Button */}
        <div onClick={() => navigate("/")}>
          <HomeIcon className="w-6 h-6 cursor-pointer hover:text-slate-600" />
        </div>

        {/* Breadcrumb Items */}
        {filteredRoutes.map((route, index) => (
          <React.Fragment key={route.path}>
            <span
              className={`tracking-wider cursor-pointer hover:text-slate-800 ${
                index === filteredRoutes.length - 1 ? "text-slate-900" : ""
              }`}
              onClick={() =>
                index < filteredRoutes.length - 1 && navigate(route.path)
              }
            >
              {route.name.replace(/-/g, " ")}
            </span>
            {/* Add separator if not the last item */}
            {index < filteredRoutes.length - 1 && "/"}
          </React.Fragment>
        ))}
      </div>
    </div>
  ) : null;
}
